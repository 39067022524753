html, body {
  width: 100%;
  height: 100%;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  font-weight: 400;
  overflow: hidden;
}

body {
  text-rendering: optimizelegibility;
  margin: 0;
}

a, a:visited {
  color: #0072ce;
  text-decoration: none;
}

h2 {
  margin: 7px 0;
  font-size: 20px;
}

h2 a {
  color: #111;
  display: block;
}

#map {
  position: absolute;
  inset: 0;
}

#about {
  width: 38px;
  margin: -15px -15px -15px 0;
  padding: 18px 15px 15px;
  font-size: 1.1em;
}

#about:hover {
  text-decoration: underline;
}

#about a, #about ai {
  color: #404040;
  text-shadow: 0 1px 1px #ffffffe6;
  font-weight: bold;
  display: block;
}

#grid-container {
  z-index: 2;
  position: relative;
  overflow-y: scroll;
}

div.exit {
  width: 25px;
  height: 25px;
  color: #fff;
  cursor: pointer;
  font-size: 25px;
  line-height: 25px;
  position: absolute;
  top: 15px;
  right: 15px;
}

div.exit:hover {
  color: gray;
}

#current-location {
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-right: 2px;
  display: inline-block;
}

#current-location svg path {
  fill: #777;
}

#current-location:hover svg path {
  fill: #000;
}

.og-grid {
  padding-top: 0;
}

.og-close {
  display: none;
}

.og-expander-inner {
  padding: 20px 30px;
}

.og-details {
  width: 40%;
  padding-right: 0;
}

.og-fullimg {
  width: 60%;
}

.og-fullimg > img {
  transition: transform .2s;
}

#expanded {
  background: #00000054;
  position: fixed;
  inset: 0;
}

.expanded-panel {
  background: #000c;
  flex-direction: column;
  padding: 50px 50px 0;
  display: flex;
  position: absolute;
  inset: 25px;
}

#expanded .og-fullimg img:hover {
  cursor: pointer;
}

#expanded .header {
  background: none;
}

#expanded .logo {
  vertical-align: top;
  display: inline-block;
}

#expanded .logo a {
  color: #fff;
  cursor: pointer;
}

#expanded .logo a:hover {
  color: gray;
}

#filtered-slideshow {
  color: #fff;
  font-size: 1.4em;
  position: absolute;
  top: 20px;
  left: 55px;
}

a#slideshow-all, a#slideshow-all:visited {
  color: #fff;
  text-decoration: underline;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 40px solid #0000;
  border-bottom: 40px solid #0000;
  border-left: 50px solid #dadada;
}

.arrow-left {
  width: 0;
  height: 0;
  border-top: 40px solid #0000;
  border-bottom: 40px solid #0000;
  border-right: 50px solid #dadada;
}

.arrow-left:hover {
  border-right: 50px solid #fff;
}

.arrow-right:hover {
  border-left: 50px solid #fff;
}

.spacer {
  height: 64px;
}

.rotate {
  position: absolute;
  bottom: 0;
  right: 0;
}

.description {
  font-size: 18px;
  font-weight: 500;
}

.text, .more-on-back {
  margin: 10px 0 20px;
  padding-right: 30px;
  font-size: 16px;
}

.text {
  white-space: pre-line;
}

.more-on-back {
  font-style: italic;
}

.feedback-link {
  margin: 20px 0;
  font-size: 14px;
}

.nypl-link {
  margin-top: 10px;
  font-size: 14px;
  font-style: italic;
}

.nypl-link a {
  color: #573619;
}

.comments {
  margin-top: 10px !important;
}

.og-grid li {
  margin: 0 5px 12px;
}

.og-grid li.og-expanded > a:after {
  border-bottom-color: #fff;
}

.og-expander {
  width: auto;
  background: #f5f5f5;
  right: 14px;
}

.og-details {
  background: #fff;
  overflow-y: auto;
}

.og-details h3 {
  font-size: 22px;
}

.copy-link {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  background-color: #1272ce;
  padding: 6px 12px;
  font-size: .8em;
  font-weight: 400;
  line-height: 1;
  display: inline-block;
}

.copy-link .email-share {
  color: #fff;
}

.copy-link.clicked {
  cursor: default;
}

.og-details .copy-link {
  vertical-align: top;
  display: inline-block;
  overflow: hidden;
}

.og-details .social, .more-on-back {
  margin: 10px 0;
}

.og-details > div {
  position: relative;
}

.details, .feedback {
  padding: 20px;
  position: absolute;
  left: 0;
  right: 0;
}

.feedback p, .feedback button {
  margin: 10px 0;
  padding: 5px;
  display: block;
}

.feedback input {
  padding: 5px;
}

.feedback p {
  padding-left: 0;
}

.feedback .suggest-date button {
  display: inline-block;
}

#about-page {
  position: fixed;
  inset: 0;
}

#about-page .container {
  width: 100%;
  max-width: 960px;
  background: #fff;
  margin-left: -480px;
  position: fixed;
  top: 20px;
  bottom: 20px;
  left: 50%;
}

.white-arrow-down {
  width: 0;
  height: 0;
  border-top: 10px solid #000;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  display: inline-block;
  top: 32px;
  left: 257px;
}

span.ui-slider-handle {
  outline: none;
}

.left-nav {
  width: 256px;
  z-index: 0;
  background: #f5f5f5;
  position: absolute;
  top: 25px;
  bottom: 25px;
  left: 25px;
}

.left-nav .mdl-card__title {
  color: #fff;
  background: #0072ce;
  flex: none;
}

.left-nav .TitleLogo {
  display: block;
}

.mdl-card__title {
  flex-direction: column;
}

.mapleleaf {
  display: inline-block;
}

.nav-contents {
  flex-direction: column;
  flex: auto;
  padding: 22px 22px 0;
  display: flex;
  overflow: hidden;
}

.nav-contents hr {
  margin: 22px 0;
}

input#location-search {
  height: 38px;
  width: 100%;
  box-sizing: border-box;
  padding: 9px;
  font-size: 10px;
}

.feedback-about {
  height: 21px;
  margin-top: 6px;
  position: absolute;
  top: 25px;
  right: 25px;
}

.feedback-about a {
  color: #fff;
  text-transform: uppercase;
  background: #1272ce;
  margin-right: 5px;
  padding: 6px 11px;
}

.search-icon {
  background: #fff;
  margin-top: 9px;
  position: absolute;
  right: 31px;
}

div#time-slider {
  margin-top: -1px;
  margin-left: 1px;
  margin-right: 21px;
}

#popular {
  flex: auto;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  width: 6px;
  height: 6px;
  background-color: #3539474d;
  border-radius: 1ex;
  padding: 8px;
}

#grid-container::-webkit-scrollbar {
  width: 3px;
  background-color: #ccc9;
}

#grid-container::-webkit-scrollbar-thumb {
  width: 3px;
  background-color: #f5f5f5;
}

.header h6 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
  display: inline-block;
}

.details .label {
  font-size: 12px;
  font-weight: 600;
}

.details .value {
  margin-bottom: 1em;
  font-size: 12px;
  font-weight: 400;
  line-height: 1em;
}

.details .title {
  margin: 20px auto;
  font-size: 18px;
  font-weight: 600;
}

.details .value.subject {
  white-space: pre-line;
}

.popup:hover {
  cursor: pointer;
}

.popup .photo-count {
  color: #fff;
  background: #60b8ff;
  border-color: #60b8ff;
  border-radius: 12px;
  padding: 4px 5px;
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 5px;
  right: 5px;
  box-shadow: 0 6px 6px #0000003d;
}

.popup .title {
  padding: 5px;
  font-size: 10px;
}

.popup-tip-anchor {
  height: 0;
  width: 200px;
  position: absolute;
}

.popup-bubble-anchor {
  position: absolute;
  bottom: 9px;
  left: 0;
}

.popup-bubble-anchor:after {
  content: "";
  width: 0;
  height: 0;
  border-top: 8px solid #fff;
  border-left: 6px solid #0000;
  border-right: 6px solid #0000;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%);
}

.popup-bubble-content {
  background-color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  overflow-y: auto;
  transform: translate(-50%, -100%);
  box-shadow: 0 4px 5px #00000024, 0 1px 10px #0000001f, 0 2px 4px -1px #0003;
}

.time-range-ui {
  padding-top: 12px;
}

.time-range-ui img {
  margin-left: 15px;
}

span.ui-slider-handle.ui-corner-all.ui-state-default {
  width: 20px;
  height: 20px;
  background: url("knob-blue.c5f59ab8.png") center / 20px no-repeat;
  border: 0;
  margin-top: -1px;
}

.ui-slider-horizontal.ui-widget.ui-widget-content {
  height: 3px;
  background: #ccc;
  border: 0;
}

.ui-widget-header {
  background: #60b8ff;
}

.time-chart-container {
  margin-bottom: 30px;
  margin-left: 15px;
  position: relative;
}

.time-chart {
  width: 177px;
  height: 25px;
}

.time-chart-bg {
  background: url("chart-bg.e843a9ce.png") 0 0 / 177px 25px no-repeat;
}

.time-chart-fg {
  background: url("chart-fg.cc1c87cf.png") 0 0 / 177px 25px no-repeat;
  position: absolute;
}

h1 {
  margin: 10px auto;
}

.time-range-label {
  text-align: center;
  width: 100px;
  color: #0072ce;
  margin-top: 10px;
  margin-left: -50px;
  font-size: 16px;
  font-weight: 600;
  position: absolute;
}

.popup.no-images {
  width: 125px;
  padding: 5px;
  font-size: 10px;
}

.inline-image, .inline-close, .mobile-logo {
  display: none;
}

iframe.hide {
  width: 1px;
  height: 1px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.feedback textarea {
  width: 100%;
  max-width: 500px;
}

#clear-filters {
  color: #0072ce;
  cursor: pointer;
  text-align: right;
  margin-top: -8px;
  margin-bottom: 8px;
}

.legal a {
  color: #0072ce;
}

.legal {
  background-color: #ffffff6f;
  padding: 2px 4px;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  left: 0;
}

.short-copyright {
  display: none;
}

@media only screen and (max-width: 736px) {
  .left-nav {
    display: none;
  }

  .mobile-logo {
    color: #fff;
    z-index: 0;
    background: #0072ce;
    padding: 6px 11px;
    display: block;
    position: absolute;
    top: 25px;
    left: 25px;
    box-shadow: 0 2px 4px #0000003d;
  }

  .mobile-logo .TitleLogo {
    vertical-align: middle;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 500;
    display: inline-block;
  }

  .expanded-panel {
    padding: 10px 10px 0;
    inset: 0;
  }

  #expanded .header {
    min-height: 40px;
  }

  div.exit {
    top: 10px;
    right: 10px;
  }

  .og-previous, .og-next {
    display: none;
  }

  .og-expander-inner {
    padding: 0;
    position: fixed;
    inset: 0;
    overflow-y: auto;
  }

  .og-fullimg {
    width: 100%;
    height: auto;
    display: none;
  }

  .og-details {
    width: 100%;
    padding: 0;
  }

  body.view-photo .header {
    display: none;
  }

  body.view-photo .expanded-panel {
    padding: 0;
  }

  body.view-photo li {
    visibility: hidden;
  }

  body.view-photo li.og-expanded {
    visibility: visible;
  }

  ::-webkit-scrollbar {
    background: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #353947;
  }

  #grid-container::-webkit-scrollbar {
    background-color: #ccc;
  }

  #grid-container::-webkit-scrollbar-thumb {
    background-color: #f5f5f5;
  }

  .og-expander, li {
    transition: height 1ms !important;
  }

  .inline-image {
    width: 100%;
    height: auto;
    display: block;
  }

  .inline-close {
    text-align: right;
    background: #000;
    padding: 13px 3px 9px;
    display: block;
  }

  .legal, .long-copyright {
    display: none;
  }

  .short-copyright {
    display: inline;
  }
}

/*# sourceMappingURL=index.3076a47c.css.map */
