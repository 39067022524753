/*
@font-face {
  font-family: 'Montserrat';
}
@font-face {
  font-family: 'Montserrat';
  font-weight: bold;
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
}
@font-face {
  font-family: 'Montserrat';
  font-weight: 600;
}
*/

html, body {
  font-family:-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  /* font-size:16px; */
  font-weight: 400;
  width: 100%;
  height: 100%;
  overflow: hidden; /* disable the bounce scroll effect on OSX */
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}

body {
  text-rendering: optimizeLegibility;
  margin: 0;
}
a, a:visited {
  text-decoration: none;
  color: #0072CE;
}

h2 {
  font-size: 20px;
  margin: 7px 0px;
}
h2 a {
  display: block;
  color: #111;
}

#map {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}

#about {
  font-size: 1.1em;
  width: 38px;
  margin: -15px;
  margin-left: 0;
  padding: 15px;
  padding-top: 18px;
}
#about:hover{
  text-decoration: underline;
}
#about a, #about ai {
  display: block;
  color: #404040;
  font-weight: bold;
  text-shadow: 0 1px 1px rgba(255,255,255,.9);
}

#grid-container {
  z-index: 2;
  overflow-y: scroll;
  position: relative;
}

div.exit {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 25px;
  height: 25px;
  font-size: 25px;
  line-height: 25px;
  color: white;
  cursor: pointer;
}
div.exit:hover {
  color: gray;
}

#current-location {
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  margin-left: 2px;
  margin-right: 2px;
}
#current-location svg path {
  fill: #777;
}
#current-location:hover svg path {
  fill: black;
}

.og-grid {
  padding-top: 0;
}

/* reclaim some space */
.og-close {
  display: none;
}
.og-expander-inner {
  padding: 20px 30px;
}
.og-details {
  padding-right: 0;
  width: 40%;
}
.og-fullimg {
  width: 60%;
}
.og-fullimg > img {
  transition: transform 200ms;
}

#expanded {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.33);
}

.expanded-panel {
  position: absolute;
  background: rgba(0,0,0,0.8);
  left: 25px;
  top: 25px;
  right: 25px;
  bottom: 25px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
}

#expanded .og-fullimg img:hover {
  cursor: pointer;
}

#expanded .header {
  background: transparent;
  /* margin-bottom: 12px; */
}
#expanded .logo {
  display: inline-block;
  vertical-align: top;
}

#expanded .logo a {
  color: white;
  cursor: pointer;
}
#expanded .logo a:hover {
  color: gray;
}
#filtered-slideshow {
  position: absolute;
  left: 55px;
  top: 20px;
  color: white;
  font-size: 1.4em;
  /* height: 35px; */
  /* line-height: 35px; */
}
a#slideshow-all, a#slideshow-all:visited {
  color: white;
  text-decoration: underline;
}
.arrow-right {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-left: 50px solid #DADADA;
}
.arrow-left {
  width: 0;
  height: 0;
  border-top: 40px solid transparent;
  border-bottom: 40px solid transparent;
  border-right:50px solid #DADADA;
}
.arrow-left:hover{
  border-right:50px solid #fff;
}
.arrow-right:hover{
  border-left:50px solid #fff;
}

.spacer {
  height: 64px;
}

/* image grid */
.rotate {
  position: absolute;
  bottom: 0;
  right: 0;
}

.description{
  /* font-family: Montserrat; */
  font-weight: 500;
  font-size: 18px;
}

.text,
.more-on-back {
  margin: 20px 0;
  margin-top: 10px;
  padding-right: 30px;
  font-size: 16px;
}

.text {
  white-space: pre-line;
}

.more-on-back {
  font-style: italic;
}

.feedback-link {
  margin: 20px 0;
  font-size: 14px;
}

.nypl-link {
  font-style: italic;
  font-size: 14px;
  margin-top: 10px;
}
.nypl-link a {
  color: rgb(87, 54, 25);
}

.comments{
  margin-top: 10px !important;
}

.og-grid li {
  margin: 0 5px 12px 5px;  /* moves margin from top to bottom */
}

.og-grid li.og-expanded > a::after {
  border-bottom-color: #fff;
}

.og-expander {
  background: #f5f5f5;
  width: auto;
  right: 14px;
}

.og-details {
  overflow-y: auto;
  background: white;
}

.og-details h3 {
  font-size: 22px;
}

.copy-link {
  padding: 6px 12px;
  background-color: #1272ce;
  color: white;
  font-size: 0.8em;
  font-weight: 400;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 0.8em;
  line-height: 1;
}
/* .copy-link:hover {

} */

.copy-link .email-share {
  color: white;
}
.copy-link.clicked {
  cursor: default;
}

.og-details .copy-link {
  display: inline-block;
  vertical-align: top;
  overflow: hidden;  /* this hides the "Like" flyout */
}

.og-details .social, .more-on-back {
  margin: 10px 0;
}
.og-details > div {
  position: relative;
}

.details, .feedback {
  position: absolute;
  left: 0;
  right: 0;
  padding: 20px;
}

.feedback p, .feedback button {
  padding: 5px;
  margin: 10px 0;
  display: block;
}
.feedback input {
  padding: 5px;
}
.feedback p { padding-left: 0; }
.feedback .suggest-date button {
  display: inline-block;
}
/* .feedback a.back { style: italic; } */

#about-page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#about-page .container {
  background: #fff;
  position: fixed;
  top: 20px;
  left: 50%;
  width: 100%;
  max-width: 960px;
  margin-left: -480px;
  bottom: 20px;
}

.white-arrow-down {
  width: 0;
  height: 0;
  top: 32px;
  left: 257px;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 10px solid black;
  display: inline-block;
}

span.ui-slider-handle {
  outline: none;
}

.left-nav {
  width: 256px;
  position: absolute;
  top: 25px;
  left: 25px;
  bottom: 25px;
  background: rgb(245, 245, 245);
  z-index: 0;
}

.left-nav .mdl-card__title {
  background: rgb(0, 114, 206);
  color: white;
  flex: 0 0 auto;
}

.left-nav .TitleLogo {
  display: block;
}

.mdl-card__title {
  flex-direction: column;
}

.mapleleaf {
  display: inline-block;
}

.nav-contents {
  padding: 22px;
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
}

.nav-contents hr {
  margin: 22px 0;
}

input#location-search {
  font-size: 10px;
  height: 38px;
  width: 100%;
  padding: 9px;
  box-sizing: border-box;
}

.feedback-about {
  position: absolute;
  right: 25px;
  top: 25px;
  height: 21px;
  margin-top: 6px;
}

.feedback-about a {
  background: #1272ce;
  color: white;
  text-transform: uppercase;
  padding: 6px 11px;
  margin-right: 5px;
}

.search-icon {
  position: absolute;
  right: 31px;
  margin-top: 9px;
  background: white;
}

div#time-slider {
  margin-left: 1px;
  margin-right: 21px;
  margin-top: -1px;
}

/* Popular Photos */

#popular {
  flex: 1 1 auto;
  overflow-y: auto;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 1ex;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(053,057,071,0.3);
  padding: 8px;
  width: 6px;
  height: 6px;
  border-radius: 1ex;
}

#grid-container::-webkit-scrollbar {
  background-color: rgba(204, 204, 204, 0.6);
  width: 3px;
}
#grid-container::-webkit-scrollbar-thumb {
  background-color: #F5F5F5;
  width: 3px;
}

.header h6 {
  color: white;
  display: inline-block;
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 8px;
}

.details .label {
  /* font-family: Montserrat; */
  font-weight: 600;
  font-size: 12px;
}

.details .value {
  /* font-family: Montserrat; */
  font-weight: 400;
  font-size: 12px;
  line-height: 1em;
  margin-bottom: 1em;
}

.details .title {
  /* font-family: Montserrat; */
  font-weight: 600;
  font-size: 18px;
  margin: 20px auto;
}

.details .value.subject {
  white-space: pre-line;
}

.popup:hover {
  cursor: pointer;
}

.popup .photo-count {
  background: #60B8FF;
  display: inline-block;
  position: absolute;
  right: 5px;
  top: 5px;
  color: white;
  /* font-family: Montserrat; */
  font-weight: bold;
  font-size: 14px;
  border-color: #60B8FF;
  border-radius: 12px;
  padding: 4px 5px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.24);
}

.popup .title {
  /* font-family: Montserrat; */
  font-size: 10px;
  padding: 5px;
}

/* The location pointed to by the popup tip. */
.popup-tip-anchor {
  height: 0;
  position: absolute;
  /* The max width of the info window. */
  width: 200px;
}

/* The bubble is anchored above the tip. */
.popup-bubble-anchor {
  position: absolute;
  bottom: /* TIP_HEIGHT= */ 9px;
  left: 0;
}

/* Draw the tip. */
.popup-bubble-anchor::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  /* Center the tip horizontally. */
  transform: translate(-50%, 0);
  /* The tip is a https://css-tricks.com/snippets/css/css-triangle/ */
  width: 0;
  height: 0;
  /* The tip is 8px high, and 12px wide. */
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: /* TIP_HEIGHT= */ 8px solid white;
}

/* The popup bubble itself. */
.popup-bubble-content {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -100%);
  /* Style the info window. */
  background-color: white;
  overflow-y: auto;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12), 0 2px 4px -1px rgba(0,0,0,.2);
}

.time-range-ui {
  padding-top: 12px;
}

.time-range-ui img {
  /* text-align: center; */
  margin-left: 15px;
  /* opacity: 0.5; */
}

span.ui-slider-handle.ui-corner-all.ui-state-default {
  background: url(../images/knob-blue.png) no-repeat center;
  border: 0;
  width: 20px;
  height: 20px;
  background-size: 20px;
  margin-top: -1px;
}

.ui-slider-horizontal.ui-widget.ui-widget-content {
  height: 3px;
  background: #CCCCCC;
  border: 0;
}

.ui-widget-header {
  background: #60B8FF;
}

.time-chart-container {
  margin-left: 15px;
  margin-bottom: 30px;
  position: relative;
}

.time-chart {
  width: 177px;
  height: 25px;
}

.time-chart-bg {
  background: url(../images/chart-bg.png) no-repeat left top;
  background-size: 177px 25px;
}

.time-chart-fg {
  background: url(../images/chart-fg.png) no-repeat left top;
  background-size: 177px 25px;
  position: absolute;
}

h1 {
  margin: 10px auto;
}

.time-range-label {
  position: absolute;
  text-align: center;
  width: 100px;
  margin-left: -50px;
  /* font-family: Montserrat; */
  color: #0072CE;
  font-weight: 600;
  font-size: 16px;
  margin-top: 10px;
}

.popup.no-images {
  padding: 5px;
  width: 125px;
  /* font-family: Montserrat; */
  font-size: 10px;
}

/* This is only used for mobile */
.inline-image, .inline-close {
  display: none;
}
.mobile-logo {
  display: none;
}

iframe.hide {
  position:absolute;
  top:-1px;
  left:-1px;
  width:1px;
  height:1px;
}

.feedback textarea {
  width: 100%;
  max-width: 500px;
}

#clear-filters {
  color: #0072CE;
  cursor: pointer;
  text-align: right;
  margin-bottom: 8px;
  margin-top: -8px;
}

.legal a {
  color: #0072CE;
}

.legal {
  background-color: #ffffff6f;
  font-weight: 500;
  padding: 2px 4px;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.short-copyright {
  display: none;
}

/* Responsive UI for mobile */

@media only screen and (max-width: 736px) {

  .left-nav {
    display: none;
  }
  .mobile-logo {
    display: block;
    position: absolute;
    top: 25px;
    left: 25px;
    background: rgb(0, 114, 206);
    color: white;
    z-index: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.24);
    padding: 6px 11px;
  }
  .mobile-logo .TitleLogo {
    display: inline-block;
    vertical-align: middle;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .expanded-panel {
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    padding-bottom: 0;
  }

  #expanded .header {
    min-height: 40px;  /* kludge to make the exit button visible on mobile */
  }

  div.exit {
    top: 10px;
    right: 10px;
  }

  .og-previous, .og-next {
    display: none;
  }

  .og-expander-inner {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* z-index: 10; */
    padding: 0;
    overflow-y: auto;
  }

  .og-fullimg {
    width: 100%;
    height: auto;
    display: none;
  }

  .og-details {
    width: 100%;
    padding: 0;
  }

  /*
    Mobile Safari has some issues with z-index.
    These rules hide elements that incorrectly show through the photo viewer.
  */
  body.view-photo .header {
    display: none;
  }
  body.view-photo .expanded-panel {
    padding: 0;
  }
  body.view-photo li {
    visibility: hidden;
  }
  body.view-photo li.og-expanded {
    visibility: visible;
  }

  *::-webkit-scrollbar {
    background: white;
  }

  *::-webkit-scrollbar-thumb {
    background-color: rgb(53,57,71);
  }

  #grid-container::-webkit-scrollbar {
    background-color: rgb(204, 204, 204);
  }
  #grid-container::-webkit-scrollbar-thumb {
    background-color: #F5F5F5;
  }

  .og-expander, li {
    /*
    The transition looks bad in the mobile UI, so we just want to get it over with quickly.
    We can't set "none" because the grid UI depends on transition end events.
    */
    transition: height 1ms !important;
  }

  .inline-image {
    display: block;
    width: 100%;
    height: auto;
  }

  .inline-close {
    display: block;
    background: black;
    text-align: right;
    padding: 13px 3px 9px 3px;
  }

  .legal {
    display: none;
  }
  .long-copyright {
    display: none;
  }
  .short-copyright {
    display: inline;
  }
}
